import axios from "axios";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { PageContext } from "../../context-provider/PageContext";
import PageTitle from "../page-title/pageTitle";
import "./Alert.css";

const Alert = () => {
  const { togglePage } = useContext(PageContext);
  const [id, setId] = useState("");
  const [serie, setSerie] = useState("");
  const [passNum, setPassNum] = useState("");

  const postReqs = (event) => {
    event.preventDefault();
    let data = {
      student_id: id,
      passport_series: serie,
      passport_number: passNum,
    };
    axios
      .post("https://b.xitt.uz/login/", data)
      .then((res) => {
        if (res.status === 201) {
          togglePage("message");
          localStorage.setItem("r_T", res.data?.refresh);
          localStorage.setItem("a_T", res.data?.access);
        } else {
          toast.error("Error!");
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="alert">
      <div className="container">
        <PageTitle title="Testga kirishni tasdiqlang" />
        <div className="box mt-3">
          <h6 className="info mb-3">
            Arizangiz tasdiqlangan holatda telegram bot sizga talaba ID
            yuboradi! Hujjat topshiring va botdan arizangiz holatini tekshiring!
          </h6>
          <form>
            <div className="mb-4">
              <label htmlFor="id" className="form-label">
                Talaba ID
              </label>
              <input
                type="text"
                className="form-control"
                id="id"
                placeholder="Talaba ID"
                onChange={(e) => setId(e.target.value)}
                value={id}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="passport_number" className="form-label">
                Passport seriyasi
              </label>
              <input
                type="text"
                className="form-control"
                id="passport_number"
                placeholder="AA"
                onChange={(e) => setSerie(e.target.value?.toUpperCase())}
                value={serie?.toUpperCase()}
                maxLength="2"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="passport_number" className="form-label">
                Passport raqami
              </label>
              <input
                type="text"
                className="form-control"
                id="passport_number"
                placeholder="7007070"
                onChange={(e) => setPassNum(e.target.value)}
                value={passNum}
                maxLength="7"
              />
            </div>
            <p className="text-center">Barcha maydonni to’g’ri to’ldiring*</p>

            <div className="text-center">
              <button className="button" onClick={postReqs}>
                Tasdiqlash
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Alert;
