import React from 'react'
import facebook from '../../assets/facebook.svg'
import instagram from '../../assets/instagram.svg'
import phone from '../../assets/phone.svg'
import telegram from '../../assets/telegram.svg'
import PageTitle from '../page-title/pageTitle'
import './contact.css'

const Contacts = () => {
	return (
		<>
			<div className='container'>
				<div className='row contact'>
					<PageTitle title='Kontaklar' />

					<div className='contact-box'>
						<div className='contact-box-item'>
							<div className='phone'>
								<img src={phone} alt='' />
								<p>+998 78 113-80-82</p>
								<span>||</span>
								<a href='tel:+998781138082'>Qo'ng'iroq qilish</a>
							</div>
							<div className='facebook'>
								<img src={facebook} alt='' />
								<p>XITT.2023</p>
								<span>||</span>
								<a href='https://www.facebook.com/profile.php?id=61554153984897'>
									Havolaga o'tish
								</a>
							</div>
						</div>
						<div className='contact-box-item'>
							<div className='telegram'>
								<img src={telegram} alt='' />
								<p>XITT_2023</p>
								<span>||</span>
								<a href='https://t.me/XITT_2023'>Havolaga o'tish</a>
							</div>
							<div className='instagram'>
								<img src={instagram} alt='' />
								<p>XITT.2023</p>
								<span>||</span>
								<a href='https://instagram.com/XITT.2023'>Havolaga o'tish</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Contacts
