import React from "react";
import PageTitle from "../page-title/pageTitle";
import litsenziya1 from "../../assets/Litsenziya1.png";
import litsenziya2 from "../../assets/Litsenziya2.png";
import "./documents.css";

const Documents = () => {
  return (
    <div className="container litsenziya mb-5">
      <div className="row" id="scrollspyHeading4">
        <PageTitle title={"Litsenziya"} />

        <div className="col-xl-6 col-12 docs">
          <img src={litsenziya1} className="img" alt="" />
        </div>
        <div className="col-xl-6 col-12 docs">
          <img src={litsenziya2} className="img" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Documents;
