import React from "react";
import Logo from "../../assets/logo-white.svg";
import "./footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container-md container-fluid-sm ">
        <div className="footer-box">
          <img src={Logo} alt="" />
          <p>Barcha huquqlar ximoyalangan. Qarshi - 2023</p>
          <p>designed by @shohruh_raxmatov</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
