import React, { useState } from "react";

export const PageContext = React.createContext();

function PageProvider(props) {
  const [page, setPage] = useState("main");
  const [course, setCourse] = useState(null);
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);
  const [file4, setFile4] = useState(null);

  const togglePage = (val) => {
    setPage(val);
  };

  const toggleCourse = (val) => {
    setCourse(val);
  };

  const toggleFile = (files, fileType) => {
    switch (fileType) {
      case "3x4":
        setFile1(files[files.length - 1]);
        break;
      case "passport":
        setFile2(files[files.length - 1]);
        break;
      case "diplom":
        setFile3(files[files.length - 1]);
        break;
      case "ilova":
        setFile4(files[files.length - 1]);
        break;
    }
  };

  return (
    <PageContext.Provider
      value={{
        page,
        togglePage,
        course,
        toggleCourse,
        toggleFile,
        file1,
        file2,
        file3,
        file4,
      }}
    >
      {props.children}
    </PageContext.Provider>
  );
}
export default PageProvider;
