import React from 'react'
import Img from '../../assets/img-home-page.svg'
import './home.css'

const Home = () => {
	return (
		<>
			<div className='container home'>
				<div className='row'>
					<div className='col-xl-6 col-12 mt-lg-5'>
						<div className='text-box'>
							<div className='d-flex flex-column justify-content-between'>
								<p>Assalom-u Alaykum!</p>
								<h1 className='my-3 motto'>
									Sizning sifatli ta’lim olishingiz biz uchun muhim...
								</h1>
								<p>Onlayn test sinovlaridan muvafaqqiyat bilan o’ting...</p>
							</div>
						</div>
						<div className='btn-group'>
							<a className='button' href="#yo'nalishlar">
								Hujjat topshirish
							</a>
							<a className='button' href='tel:+998781138082'>
								Qo'ng'iroq qilish
							</a>
						</div>
					</div>
					<div className='col-xl-6 col-12 mt-md-3 mt-5'>
						<img className='w-100' src={Img} alt='' />
					</div>
				</div>
			</div>
		</>
	)
}

export default Home
