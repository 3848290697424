import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Modal } from "reactstrap";
import downloadImg from "../../assets/download.svg";
import { PageContext } from "../../context-provider/PageContext";
import PageTitle from "../page-title/pageTitle";
import image from "../../assets/quiz-img.svg";

import "./contract.css";

const Contract = () => {
  const { course } = useContext(PageContext);
  const [modal, setModal] = useState(false);
  const [courses, setCourses] = useState([]);
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [fathersName, setFathersName] = useState("");
  const [phone, setPhone] = useState("");
  const [addPhone, setAddPhone] = useState("");
  const [passSeries, setPassSeries] = useState("");
  const [passNumber, setPassNumber] = useState("");
  const [pdfUrl, setPdfUrl] = useState("#");

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    try {
      const response = await fetch("https://b.xitt.uz/directions/");
      const data = await response.json();
      setCourses(data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const onSubmitFormData = async () => {
    const fd = new FormData();
    fd.append("first_name", firstName);
    fd.append("last_name", lastName);
    fd.append("fathers_name", fathersName);
    fd.append("phone_number", phone);
    fd.append("additional_phone_number", addPhone);
    fd.append("passport_series", passSeries);
    fd.append("passport_series_number", passNumber);
    fd.append("direction", course);

    if (
      firstName.length &&
      lastName.length &&
      fathersName.length &&
      phone.length &&
      addPhone.length &&
      passSeries.length &&
      passNumber.length
    ) {
      try {
        axios({
          url: "https://b.xitt.uz/application/",
          data: fd,
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((res) => {
            if (res.status === 200) {
              setPdfUrl(res?.data?.document);
              setModal(!modal);
              toast.success("Hujjat muvaffaqiyatli topshirildi!");
            }
          })
          .catch((err) => {
            setModal(!modal);
            console.log(err);
            toast.warning(
              "Barcha bandlar to'gri to'ldirib, qaytadan yuklang !!!"
            );
          });
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    } else {
      toast.warning("Fo'rmani to'liq to'ldiring!!!");
    }
  };

  const toggle = () => {
    setModal(!modal);
  };

  const toHome = () => {
    window.location.href = "/";
  };
  return (
    <>
      <div className="contract mt-4">
        <div className="container">
          <PageTitle title="Hujjat topshirish" />
          <h3>Shaxsiy ma'lumotlar</h3>
          <form>
            <div className="row">
              <div className="col-md-4">
                <div className="mb-4">
                  <label htmlFor="first_name" className="form-label">
                    Ismingiz (Lotin alifbosida)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="first_name"
                    placeholder="Ismingiz (Lotin alifbosida)"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label htmlFor="last_name" className="form-label">
                    Familiyangiz (Lotin alifbosida)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="last_name"
                    placeholder="Familiyangiz (Lotin alifbosida)"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label htmlFor="fathers_name" className="form-label">
                    Otangizning ismi (Lotin alifbosida)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="fathers_name"
                    placeholder="Otangizning ismi (Lotin alifbosida)"
                    value={fathersName}
                    onChange={(e) => setFathersName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="mb-4">
                  <label htmlFor="passSeries" className="form-label">
                    Passport seriyasi
                  </label>
                  <input
                    id="passSeries"
                    type="text"
                    className="form-control"
                    placeholder="--"
                    maxLength={2}
                    value={passSeries}
                    onChange={(e) =>
                      setPassSeries(e.target.value?.toUpperCase())
                    }
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="mb-4">
                  <label htmlFor="passNumber" className="form-label">
                    Passport raqami
                  </label>
                  <input
                    id="passNumber"
                    type="text"
                    className="form-control"
                    placeholder="-------"
                    maxLength={7}
                    value={passNumber}
                    onChange={(e) => setPassNumber(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="mb-4">
                  <label htmlFor="phone" className="form-label">
                    Telefon raqamingiz
                  </label>
                  <div className="input-group flex-nowrap">
                    <span className="input-group-text" id="addon-wrapping">
                      +998
                    </span>
                    <input
                      id="phone"
                      type="text"
                      className="form-control"
                      placeholder="90-123-46-67"
                      maxLength={9}
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="mb-4">
                  <label htmlFor="addPhone" className="form-label">
                    Qo’shimcha telefon raqamingiz
                  </label>
                  <div className="input-group flex-nowrap">
                    <span className="input-group-text" id="addon-wrapping">
                      +998
                    </span>
                    <input
                      id="addPhone"
                      type="text"
                      className="form-control"
                      placeholder="90-123-46-67"
                      maxLength={9}
                      value={addPhone}
                      onChange={(e) => setAddPhone(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <h3>Topshirish jarayoni</h3>
                <div className="mb-4">
                  <label htmlFor="passSeries" className="form-label">
                    Qaysi kursga o'tmoqchisiz
                  </label>
                  <select name="course" className="form-select">
                    <option value="1">1-kurs</option>
                    <option value="2">2-kurs</option>
                    <option value="3">3-kurs</option>
                    <option value="4">4-kurs</option>
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <h3 style={{ color: "#fff" }}>.</h3>
                <div className="mb-4">
                  <label htmlFor="passSeries" className="form-label">
                    Ta'lim yo'nalishi
                  </label>
                  <select
                    name="course_of_study"
                    className="form-select"
                    value={course}
                  >
                    {courses?.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <h3 style={{ color: "#fff" }}>.</h3>
                <div className="mb-4">
                  <label htmlFor="passSeries" className="form-label">
                    Ta'lim shakli
                  </label>
                  <select name="form_of_education" className="form-select">
                    <option value="1">Kunduzgi</option>
                    <option value="2">Kechki</option>
                    <option value="3">Sirtqi</option>
                  </select>
                </div>
              </div>

              <div className="col-12 mb-5 pb-5">
                <div className="btn-group">
                  <a
                    className="button mt-5"
                    href="#"
                    type="button"
                    onClick={onSubmitFormData}
                  >
                    Hujjat topshirish
                  </a>
                </div>
              </div>
            </div>
          </form>
        </div>

        {/* modal */}
        <Modal isOpen={modal}>
          <div className="modal-dialog modal-dialog-centered m-0">
            <div className="modal-content py-4">
              <div className="modal-body text-center" style={{ padding: 32 }}>
                <img src={image} alt="" />
                <p>
                  "Tabriklaymiz, sizning arizangiz muvaffaqiyatli topshirildi!"
                </p>
                <button
                  className="btn mt-4"
                  style={{ background: "#140677", color: "#fff" }}
                  onClick={toHome}
                >
                  Asosiy sahifaga qaytish
                </button>
              </div>
              <div className="files">
                <a
                  href={pdfUrl}
                  className="d-flex align-items-center justify-content-center"
                  target={"_blank"}
                >
                  <img className="me-2 mb-0" src={downloadImg} aly="" />
                  To'lov-kontrakt shartnomani yuklab olish
                </a>
              </div>
            </div>
          </div>
        </Modal>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        ></div>
      </div>
    </>
  );
};

export default Contract;
