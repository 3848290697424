import React, { useContext, useEffect, useState } from "react";
import "./courses.css";
import PageTitle from "../page-title/pageTitle";
import { PageContext } from "../../context-provider/PageContext";

const Courses = () => {
  const { togglePage, toggleCourse } = useContext(PageContext);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    try {
      const response = await fetch("https://b.xitt.uz/directions/");
      const data = await response.json();
      setCourses(data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const patternFormat = (value) => {
    return value
      ?.toString()
      .split(/(?=(?:\d{3})+(?:\.|$))/g)
      .join(" ");
  };

  return (
    <>
      <div className="container mb-5">
        <div className="row">
          <PageTitle title={"Yo'nalishlar"} />
          <div className="row mb-3 card-group">
            {courses?.map((item) => (
              <div className="col-sm-4 card-item my-3" key={item?.id}>
                <div className="card">
                  <div className="card-img-box">
                    <img src={item?.image} className="card-img-top" alt="..." />
                    <div className="card-img-text-box">
                      <h4 className="card-img-text-box-title">
                        Kerakli hujjatlar:
                      </h4>
                      <ul>
                        <li>Pasport</li>
                        <li>Attestat yoki kollej diplomi va ilovasi</li>
                        <li>3x4 rasm 4 dona</li>
                      </ul>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="card-descs">
                      <p className="card-desc-title">Yo'nalish:</p>
                      <p className="card-desc-val">{item?.name}</p>
                    </div>
                    <div className="card-descs">
                      <p className="card-desc-title">O‘qish muddati:</p>
                      <p className="card-desc-val">{item?.study_period} yil</p>
                    </div>
                    <div className="card-descs">
                      <p className="card-desc-title">Ta'lim shakli:</p>
                      <p className="card-desc-val">{item?.education_type}</p>
                    </div>
                    <div className="card-descs">
                      <p className="card-desc-title">Tanlov ustuvorligi:</p>
                      <p className="card-desc-val">
                        {item?.education_payment_type}
                      </p>
                    </div>
                    <div className="card-descs">
                      <p className="card-desc-title">Kontrakt summasi:</p>
                      <p className="card-desc-val">
                        {patternFormat(item?.contract_amount)} so'm
                      </p>
                    </div>
                    <button
                      onClick={() => {
                        togglePage("test");
                        toggleCourse(item.id);
                      }}
                      className="card-btn w-100"
                    >
                      Hujjat topshirish
                    </button>
                  </div>
                </div>
              </div>
            ))}

            {/* <div className="col-sm-4 card-item">
              <div className="card">
                <div className="card-img-box">
                  <img src={Img2} className="card-img-top" alt="..." />
                  <div className="card-img-text-box">
                    <h4 className="card-img-text-box-title">
                      Kerakli hujjatlar:
                    </h4>
                    <ul>
                      <li>Pasport</li>
                      <li>Attestat yoki kollej diplomi va ilovasi</li>
                      <li>3x4 rasm 4 dona</li>
                    </ul>
                  </div>
                </div>
                <div className="card-body">
                  <div className="card-descs">
                    <p className="card-desc-title">Yo'nalish:</p>
                    <p className="card-desc-val">Hamshiralik ishi</p>
                  </div>
                  <div className="card-descs">
                    <p className="card-desc-title">O‘qish muddati:</p>
                    <p className="card-desc-val">2 yil</p>
                  </div>
                  <div className="card-descs">
                    <p className="card-desc-title">Ta'lim shakli:</p>
                    <p className="card-desc-val">Kunduzgi</p>
                  </div>
                  <div className="card-descs">
                    <p className="card-desc-title">Tanlov ustuvorligi:</p>
                    <p className="card-desc-val">To‘lov-kontrakt</p>
                  </div>
                  <div className="card-descs">
                    <p className="card-desc-title">Kontrakt summasi:</p>
                    <p className="card-desc-val">6.500.000 so'm</p>
                  </div>
                  <button
                    onClick={() => togglePage("test")}
                    className="card-btn w-100"
                  >
                    Hujjat topshirish
                  </button>
                </div>
              </div>
            </div>

            <div className="col-sm-4 card-item">
              <div className="card">
                <div className="card-img-box">
                  <img src={Img3} className="card-img-top" alt="..." />
                  <div className="card-img-text-box">
                    <h4 className="card-img-text-box-title">
                      Kerakli hujjatlar:
                    </h4>
                    <ul>
                      <li>Pasport</li>
                      <li>Attestat yoki kollej diplomi va ilovasi</li>
                      <li>3x4 rasm 4 dona</li>
                    </ul>
                  </div>
                </div>
                <div className="card-body">
                  <div className="card-descs">
                    <p className="card-desc-title">Yo'nalish:</p>
                    <p className="card-desc-val">Feldsher-akusherlik ishi</p>
                  </div>
                  <div className="card-descs">
                    <p className="card-desc-title">O‘qish muddati:</p>
                    <p className="card-desc-val">3 yil</p>
                  </div>
                  <div className="card-descs">
                    <p className="card-desc-title">Ta'lim shakli:</p>
                    <p className="card-desc-val">Kunduzgi</p>
                  </div>
                  <div className="card-descs">
                    <p className="card-desc-title">Tanlov ustuvorligi:</p>
                    <p className="card-desc-val">To‘lov-kontrakt</p>
                  </div>
                  <div className="card-descs">
                    <p className="card-desc-title">Kontrakt summasi:</p>
                    <p className="card-desc-val">6.000.000 so'm</p>
                  </div>
                  <button
                    onClick={() => togglePage("test")}
                    className="card-btn w-100"
                  >
                    Hujjat topshirish
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Courses;
