import React from "react";
import "./pageTitle.css";
import Line from "../../assets/Line.svg";

const PageTitle = ({ title }) => {
  return (
    <div className="page-title">
      <img src={Line} alt="" />
      <h4>{title}</h4>
      <img src={Line} alt="" />
    </div>
  );
};

export default PageTitle;
