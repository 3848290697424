import React, { useContext, useEffect, useState } from "react";
import PageTitle from "../page-title/pageTitle";
import "./Quiz.css";
import image from "../../assets/quiz-img.svg";
import downloadImg from "../../assets/download.svg";
import MyTimer from "../timer/MyTimer";
import axios from "axios";
import { toast } from "react-toastify";
import { Modal } from "reactstrap";
import { PageContext } from "../../context-provider/PageContext";

const Quiz = () => {
  const { togglePage } = useContext(PageContext);
  const time = new Date();
  time.setSeconds(time.getSeconds() + 3600);
  const token = localStorage.getItem("a_T");

  const [quesions, setQuesions] = useState([]);
  const [show, setShow] = useState(false);
  const [point, setPoint] = useState(0);
  const [pdfUrl, setPdfUrl] = useState("#");

  useEffect(() => {
    axios({
      url: "https://b.xitt.uz/start/exam/",
      data: null,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data?.status === "error") {
          toast.error(res.data?.message);
          togglePage("alert");
        } else {
          setQuesions(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    var answers = [];
    var inpList = document.querySelectorAll(".form-check-input");
    for (let i = 0; i < inpList.length; i++) {
      if (inpList[i]?.checked) {
        answers.push(inpList[i]?.id * 1);
      }
    }

    axios({
      url: "https://b.xitt.uz/ask/answers/",
      data: { answers: answers },
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data?.status === "error") {
          toast.info(
            `${res.data.message}. To'plagan balingiz ${res.data.point} ball.`
          );
          setPoint(res.data.point);
          togglePage('main')
        } else {
          setPoint(res.data.point);
          if (res.data.point >= 20) {
            axios({
              url: "https://b.xitt.uz/contract/",
              data: {},
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }).then((res) => {
              setPdfUrl(res?.data?.contract);
              toggle();
            });
          } else {
            toggle();
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggle = () => setShow(!show);

  const toHome = () => {
    window.location.href = "/";
  };
  return (
    <div className="quiz">
      <div className="container px-sm-5">
        <MyTimer expiryTimestamp={time} />
        <form onSubmit={onSubmit}>
          {quesions?.map((item) => (
            <div key={item.id} className="mt-5">
              <PageTitle title={item.module_name} />
              {item?.questions?.map((child,index) => (
                <div key={child.id}>
                  <div className="question">
                    <span className="text-dark">{index+1}-Savol.</span>
                    {child?.question}
                  </div>
                  {child?.answers?.map((child_item) => (
                    <div className="form-item">
                      <div className="form-check" key={child_item.id}>
                        <input
                          className="form-check-input"
                          type={`radio`}
                          name={`radio${child.id}`}
                          id={`${child_item.id}`}
                        />
                        <label
                          className="form-check-label"
                          for={`${child_item.id}`}
                        >
                          {child_item.name}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
          <button type="submit" className="button mt-5">
            Testni yakunlash
          </button>
        </form>
      </div>

      {/* modal */}
      <Modal isOpen={show} toggle={toggle}>
        <div className="modal-dialog modal-dialog-centered m-0">
          <div className="modal-content quiz-modal">
            <div className="modal-body text-center" style={{ padding: 32 }}>
              <h5>
                Jami to’plangan ball <span>{point} ball</span>
              </h5>
              {point >= 20 ? <img src={image} alt="" /> : <></>}
              <p>
                {point >= 20
                  ? "Tabriklaymiz, siz testdan muvafaqqiyatli o’tdingiz!"
                  : "Afsuski, siz testdan o'tolmadiz."}
              </p>
              {point >= 20 ? (
                <div className="files">
                  <a href={pdfUrl} className="d-flex align-items-center" target="_blank">
                    <img className="me-2 mb-0" src={downloadImg} aly="" />
                    To'lov-kontrakt shartnomani yuklab olish
                  </a>
                </div>
              ) : (
                <></>
              )}

              <button
                className="btn mt-4"
                style={{ background: "#140677", color: "#fff" }}
                onClick={toHome}
              >
                Asosiy sahifaga qaytish
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Quiz;
