import { ToastContainer, toast } from "react-toastify";
import Navbar from "./components/navbar/navbar";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Home from "./components/home/home";
import Courses from "./components/course/courses";
import Adress from "./components/adress/adress";
import Documents from "./components/litsenziya/documents";
import Contacts from "./components/contact/contacts";
import { useContext } from "react";
import Contract from "./components/contract/contract";
import { PageContext } from "./context-provider/PageContext";
import Footer from "./components/footer/footer";
import Alert from "./components/alert/Alert";
import Message from "./components/message/Message";
import Quiz from "./components/quiz/Quiz";
import axios from "axios";

function App() {
  const { page } = useContext(PageContext);

  return (
    <>
      <ToastContainer />
      <Navbar />
      {page === "main" ? (
        <main>
          <section id="home">
            <Home />
          </section>
          <section id="yo'nalishlar">
            <Courses />
          </section>
          <section id="manzil">
            <Adress />
          </section>
          <section id="litsenziya">
            <Documents />
          </section>
          <section id="kontaktlar">
            <Contacts />
          </section>
        </main>
      ) : page === "alert" ? (
        <main>
          <section className="pt-4" id="yo'nalishlar">
            <Alert />
          </section>
        </main>
      ) : page === "message" ? (
        <main>
          <section className="pt-4" id="yo'nalishlar">
            <Message />
          </section>
        </main>
      ) : page === "quiz" ? (
        <main>
          <section className="pt-4" id="yo'nalishlar">
            <Quiz />
          </section>
        </main>
      ) : (
        <main>
          <section className="pt-4" id="yo'nalishlar">
            <Contract />
          </section>
        </main>
      )}
      <Footer />
    </>
  );
}

export default App;
