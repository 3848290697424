import React from "react";
import PageTitle from "../page-title/pageTitle";
import "./adress.css";
import Img1 from "../../assets/address_img1.svg";
import Img2 from "../../assets/address_img2.svg";
import Img3 from "../../assets/address_img3.svg";
import locationSvg from "../../assets/location.svg";

const Adress = () => {
  return (
    <>
      <div className="container adress mb-5">
        <div className="row">
          <PageTitle title={"Manzil"} />
          <div className="address">
            <div className="row">
              <div className="col-md-5 col-12">
                <img src={Img1} className="adress-first-img" alt="" />
              </div>
              <div className="col-md-3 col-12">
                <img src={Img2} alt="" className="adress-img" />
                <img src={Img3} alt="" className="adress-img mt-3" />
              </div>
              <div className="col-md-4 col-12">
                <div className="addres-location">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12431.731587142842!2d65.7947398!3d38.8339976!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d32f1974ee006fd%3A0x7b5941dd333edca7!2sXalqaro%20innovatsion%20tibbiyot%20texnikumi!5e0!3m2!1suz!2s!4v1706071833681!5m2!1suz!2s"
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
                <div className="addres-location-info">
                  <img src={locationSvg} alt="" sizes="" />
                  <div className="addres-location-info-text">
                    <p className="mb-1">
                      Qashqadaryo viloyati Qarshi sh. Paxtazor MFY 1/82-uy
                    </p>
                    <p className="mb-0">
                      Orientr: Qarshi shahar hokimligi yonida
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Adress;
