import React, { useContext } from "react";
import { PageContext } from "../../context-provider/PageContext";
import PageTitle from "../page-title/pageTitle";
import "./Message.css";

const Message = () => {
  const { togglePage } = useContext(PageContext);

  return (
    <div className="message">
      <div className="container">
        <PageTitle title="Test topshirish" />
        <div className="box">
          <h5>Diqqat</h5>
          <p>
            Hurmatli abituriyent siz
            kirish imtihonlaridan 20 ball va undan yuqori ball to’plasangiz
            oshirilmagan kontrakt to’lovi orqali texnikum talabalari safiga
            qabul qilinasiz. 20 balldan kam to’plagan abituriyentlar
            <span>1 mln so’m</span> oshirilgan kontrakt to’lovi orqali Tibbiyot
            texnikumi talabalari safiga qabul qilinadi.
          </p>
          <p>
            Barcha savolarni ishlashingiz uchun
            <span className="text-dark">1 soat</span> vaqt beriladi.
          </p>
          <p>Testni faqat bir marta ishlashingiz mumkin.</p>
          <div className="text-center">
            <button className="button" onClick={() => togglePage("quiz")}>
              Testni boshlash
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Message;
