import React, { useContext } from "react";
import "./navbar.css";
import Logo from "../../assets/logo.svg";
import Scrollspy from "react-scrollspy";
import { PageContext } from "../../context-provider/PageContext";

const Navbar = () => {
  const { togglePage } = useContext(PageContext);

  const toggleMenu = () => {
    var menuBtn = document.getElementById("btn-menu");
    menuBtn.classList = "navbar-toggler collapsed";

    var menuId = document.getElementById("navbarSupportedContent");
    menuId.classList = "navbar-collapse mt-xl-0 mt-3 collapse";
  };
  return (
    <div>
      <nav className="navbar navbar-expand-lg fixed-top">
        <div className="container-xl container-fluid">
          <a className="navbar-brand" href="#home">
            <img src={Logo} alt="" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            id="btn-menu"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="#140677"
              className="bi bi-list"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
              />
            </svg>
          </button>
          <div
            className="collapse navbar-collapse mt-xl-0 mt-3"
            id="navbarSupportedContent"
          >
            <Scrollspy
              items={[
                "home",
                "yo'nalishlar",
                "manzil",
                "litsenziya",
                "kontaktlar",
              ]}
              currentClassName="is-current"
              className="navbar-nav mx-auto mb-2 mb-lg-0"
            >
              <li className="nav-item mx-4">
                <a
                  href="#home"
                  id="link"
                  className={`nav-link`}
                  onClick={() => {
                    togglePage("main");
                    toggleMenu();
                  }}
                >
                  Bosh sahifa
                </a>
              </li>
              <li className="nav-item mx-4">
                <a
                  href={"#yo'nalishlar"}
                  id="link"
                  className={`nav-link`}
                  onClick={() => {
                    togglePage("main");
                    toggleMenu();
                  }}
                >
                  Yo'nalishlar
                </a>
              </li>
              <li className="nav-item mx-4">
                <a
                  href={"#manzil"}
                  id="link"
                  className={`nav-link`}
                  onClick={() => {
                    togglePage("main");
                    toggleMenu();
                  }}
                >
                  Manzil
                </a>
              </li>
              <li className="nav-item mx-4">
                <a
                  href={"#litsenziya"}
                  id="link"
                  className={`nav-link `}
                  onClick={() => {
                    togglePage("main");
                    toggleMenu();
                  }}
                >
                  Litsenziya
                </a>
              </li>
              <li className="nav-item mx-4">
                <a
                  href={"#kontaktlar"}
                  id="link"
                  className={`nav-link `}
                  onClick={() => {
                    togglePage("main");
                    toggleMenu();
                  }}
                >
                  Kontaklar
                </a>
              </li>
            </Scrollspy>
            {/* <button
              className="btn-login"
              onClick={() => {
                togglePage("alert");
                toggleMenu();
              }}
            >
              Testni boshlash
            </button> */}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
